.gallery{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
    grid-gap: 60px; 
    justify-items: center;
}
.gallery img{
     width: 80%;
}
.gallerycont h1{
    font-size: 3rem;
    margin: 0px;
    padding: 2% 0%;
    /* font-weight: 400; */
}
.gallerycont{
    /* justify-items: center; */
    text-align: center;
    background-color: #f8f8f8;
}