
.boardingmain{
    background-image: url('/public/assets/img/deepak/petboarding1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 15rem;
    /* height: 80vh; */
    align-items: center;
    padding-bottom: 15%;
    /* text-align: center; */
    /* height: 30vh; */
    margin-top: 1%;
    display: flex;
    justify-content: space-evenly;
    color: white;
    background-position: center center;
}
.petboarding_containt h2{
   font-size: 3rem;
}
.petboarding_containt button{
    /* margin-right: 3rem; */
    background-color: #e7196b;
    border: none;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 700;
    height: 48px;
    line-height: 26px;
    padding: 5px 20px;
    color: #ffffff;
    cursor: pointer;
}
.offer h2{
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.offer h3{
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 1px;
}  
.boardingmain span{
    color: #4cabfe;
}
.petb_content a{
    display: none;
}
@media only screen and (max-width: 1170px){
    .petboarding_containt h2{
        font-size: 2.3rem;
     }
     .petboarding_containt button{
         font-size: 13px;
         height: 48px;
         padding: 5px 20px;
     }
     .offer h2{
       font-size: 1.3rem;
     }
     .offer h3{
         font-size: 0.9rem;
         font-weight: 400;
         letter-spacing: 1px;
     }  
     .petb_content ul{
        font-size: 0.8rem;
     }
}
@media only screen and (max-width: 724px){
    .petboarding_containt h2{
        font-size: 1.8rem;
     }
     .petboarding_containt button{
         font-size: 10px;
         height: 48px;
         padding: 0px 20px;
     }
     .offer h2{
       font-size: 1.1rem;
     }
     .offer h3{
         font-size: 0.7rem;
         font-weight: 400;
         letter-spacing: 1px;
     }  
     .petb_content ul{
        font-size: 0.6rem;
     }
}
@media only screen and (max-width: 550px){
    .petboarding_containt h2{
        font-size: 2.1rem;
     }
     .offer h2{
       font-size: 1.4rem;
     }
     .offer h3{
         font-size: 1rem;
         font-weight: 400;
         letter-spacing: 1px;
     }  
     .petb_content ul{
        font-size: .9rem;
     }
     

    .boardingmain{
        flex-direction:  column;
        text-align: center;
    }
    .petboarding_containt button{
        display: none;
    }
    .petb_content a{
        display: block;
        width: 80%;
        margin-top: 5%;
    }
    .petb_content a button{
        background-color: #e7196b;
        border: none;
        border-radius: 10px;
        font-weight: 700;
        font-size: 10px;
        height: 38px;
        padding: 0px 20px;
        color: #ffffff;
        cursor: pointer;
    }
}
