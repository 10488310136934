.review-section{
    display: flex;
    padding-top: 4rem;
    justify-content: space-evenly;
    padding-bottom: 4rem;
    background-color: #7fd9d8;


}
.review-section span{
    color: rgb(249, 3, 53);
    font-weight: 500;
}
.review-section p{
    font-size: 1.2rem;
    font-weight: 200;
    letter-spacing: 1px;
}
.review-section div h1{
    margin-top: 5rem;
}
.review{
    margin: 0px 10px;
    background-color: #89dedc;
    text-align: center;
    max-width:18rem;
    padding: 50px;
    /* border-style: solid; */
    /* border-color: #ff0000; */
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0,0,0,.5);
}
.review img{
    width: 15rem;
    /* border-color: black; */
    border-radius: 40%;
    max-width: 70%; /* Ensure images don't exceed their container's width */

}
.review p{
    font-weight: 400;
}
.review .name{
    font-weight: 700;
}
@media only screen and (max-width: 732px){
    .review-section{
        flex-direction: column;
        align-items: center;

    }
}