.petboarding{
    background-image: url('/public/assets/img/petboarding.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 15rem;
    color: #ff0000;
    /* height: 80vh; */
    align-items: center;
    padding-bottom: 25%;
    /* background-color: #000000; */
    opacity: 50%;
    text-align: center;
    padding-top: 600px;
    /* height: 10%; */
    background-position: center center;

}
.pett{
    background-color: #000000;
    /* opacity: 90%; */
}