/* FAQ.css */
.faq-tital{
    margin: 0px;
    margin-bottom: 4rem;
    text-align: center;
}
.faq-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .faq-item {
    margin-bottom: 20px;
    border: 1px solid #e6e6e6;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .faq-item:hover {
    transform: translateY(-5px);
  }
  
  .faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .faq-answer {
    font-size: 1rem;
    color: #666;
  }
  