.comming_soon{
    text-align: center;
    margin-top: 15%;
}
img{
    width: 20%;
    text-align: center;
}
.comming_soon h4{
    font-size: 2.1rem;
    letter-spacing: 3px;
    font-weight: 100;
    margin-left: 3%;
    margin-bottom: 10%;
}
@media only screen and (max-width: 550px){
    .comming_soon{
        margin-top: 25%;
    }
}