.whatsicon{
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 15px;
    bottom: 10px;
    font-size: 4rem;
    cursor: pointer;
    z-index: 20;

}
.callicon{
   color: rgb(69, 121, 253);
   margin-bottom: 10px;
}
.whatsappicon{
    color:  #25D366;
}