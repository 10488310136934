.about{
    margin-top: 11%;
}
.about h1{
    letter-spacing: 3px;
    text-align: center;
    font-size: 2.8rem;
    font-weight: 700;
}
.about_content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.about_content div a{
    text-decoration: none;
    color: rgb(248, 75, 0);
    background-color: #f9fc52;
}
.deepak_img{
    width: 95%;
    margin-top: 15%;
}
.about_content .dimg{
    width: 25%;
} 
.about_content p{
    margin-top: 10%;
    font-size: 1rem;
    font-weight: 00;
    letter-spacing: 0.01em; /* Adjust letter spacing */
    word-spacing: 0.01em;
    
}
@media only screen and (max-width: 550px){
    .about_content{
        flex-direction: column;
        justify-content: center;
        align-items: centers;
    }
    .about_content .dimg{
        width: 100%;
    } 
    .deepak_img{
        margin-top: 1%;
    }
    .about_content div{

        text-align: center;
    }
    .about_content .deepak_img{
        width: 50%;
    }
    .about{
        margin-top: 20%;
    }
}

  