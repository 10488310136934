.hero_video{
    z-index: -1;
    width: 100%;
    opacity: 30%;
    /* margin-top: 60px; */
    /* -webkit-filter: grayscale(100%);  */
filter: grayscale(40%);
}
.wrapper{
        background-color: #000000;
}
.hero-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    text-align: center;
}
.hero-content h1{
    font-size: 200%;
    margin: 0px;
}
.hero-content h5{
    /* font-size: 1rem; */
    font-weight: 400;
    /* margin: 0px; */
}
.hero-content h4{
    /* margin: 0px; */
    /* margin-right: 3rem; */
    background-color: #e7196b;
    /* border: none; */
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 26px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 6px;
    padding-bottom: 10.7px;
    color: #ffffff;
    cursor: pointer;
}
.hero-content span{
    color: #27a1ff;
}
.explore{
    text-decoration: none;
}
@media only screen and (max-width: 1300px) {
    .hero-content{
        font-size: 1rem;
    }
    .hero-content h4{
        font-size: 1rem;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 4px;
        padding-bottom: 8.7px;
    }
  }
@media only screen and (max-width: 992px) {
    .hero-content{
        height: 80%;
    }
  }
  
  @media only screen and (max-width: 850px) {
    .hero-content{
        height: 70%;
    }
  }
  @media only screen and (max-width: 700px){
    .hero-content{
        font-size: .7rem;
    }
    .hero-content h4{
        font-size: .7rem;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 3px;
        padding-bottom: 6.7px;
    }
  }
  
  @media only screen and (max-width: 550px) {
    .hero-content{
        display: none;
        }
  }
